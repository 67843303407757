import {useCallback, useEffect, useState} from "react";

const WALLET_AUTOLOGIN_KEY = 'walletautollogin';


export function useIsWalletAutoLoginActive() {
    const [isAutoLoginActive, _setIsAutoLoginActive] = useState(false);

    useEffect(()=>{
        setIsAutoLoginActive(localStorage.getItem(WALLET_AUTOLOGIN_KEY) === "true");
    }, [])

    const setIsAutoLoginActive = useCallback((_isAutoLoginActive)=>{
        _setIsAutoLoginActive(_isAutoLoginActive);
        localStorage.setItem(WALLET_AUTOLOGIN_KEY, _isAutoLoginActive)
    }, [_setIsAutoLoginActive])

    return {
        isAutoLoginActive,
        setIsAutoLoginActive,
    };
}