

export default function Badge({
                                  bgColor = 'bg-red-500',
                                  textColor = 'text-white',
                                  children }) {
    return (
        <span className={`${bgColor} ${textColor} font-bold mr-2 px-0.5 py-0.5`}>{children}</span>
    )
}

export function BadgeRed({ children }) {
    return (
        <Badge bgColor={'bg-red-500'}>{children}</Badge>
    )
}

export function BadgePurple({ children }) {
    return (
        <Badge bgColor={'bg-purple-500'}>{children}</Badge>
    )
}

export function BadgeYellow({ children }) {
    return (
        <Badge bgColor={'bg-yellow-300'} textColor={'text-black'}>{children}</Badge>
    )
}