import {useTranslation} from "next-i18next";
import {useOurComicContract} from "../../wallet/hooks/comic.contract";
import {useMemo} from "react";
import {getExplorerAddressUrlByChainId} from "../../wallet/helpers";
import {BadgeYellow} from "../badge";
import {useWeb3React} from "@web3-react/core";
import {COMICS_SERIES_IDS} from "../../config/meta";

export function IssueTitle({name, issueNum, contractAddress, showContract = false, className = ''}){
    const { t } = useTranslation();
    const {chainId} = useWeb3React();
    const blockExplorerAddressUrl = useMemo(
    ()=>getExplorerAddressUrlByChainId(chainId, contractAddress), [contractAddress, chainId]
    );

    return (
        <div className={`text-left text-white ${className}`}>
            <div><BadgeYellow>{t(`issue_num`, {issueId: issueNum})}</BadgeYellow></div>
            <div>
                <span className={`text-3xl lg:text-4xl mt-1 border-b border-white uppercase`}>{name}</span>
                {showContract ? <>
                        <br/>
                        <small className={`text-[0.6rem] lg:text-xs`}>
                            <a href={blockExplorerAddressUrl} target={"_blank"} rel="noreferrer">{t(`contract_address`, {address: contractAddress})}</a>
                        </small>
                    </>
                    : null}
            </div>
        </div>
    )
}


export function IssueOurPromo({seriaId, className = ''}){
    const { t } = useTranslation();

    if(seriaId !== COMICS_SERIES_IDS.NAKOMOTO_ADVENTURES){
        return null;
    }

    return (
        <div className={` ${className}`}>
            <div className={`mt-8 mb-4 font-bold text-2xl`}>{t(`page.roadmap.text.block_3.title`)}</div>
            {t(`page.roadmap.text.block_3.p`, {returnObjects: true, seria:t(`seria.nakomotoadventures.title`)}).map((text, idx)=><p key={idx} className={`mb-4`}>{text}</p>)}
            <div className={`mt-4 mb-4 font-bold text-lg`}>{t(`page.roadmap.text.block_3.subtitle`)}</div>
            <ul className="list-disc ml-4 mb-8">
                {t(`page.roadmap.text.block_3.li`, {returnObjects: true, seria:t(`seria.nakomotoadventures.title`)}).map((text, idx)=><li key={idx} className={`mb-2`}>{text}</li>)}
            </ul>
        </div>
    )
}