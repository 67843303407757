import Head from "next/head";


export const PageTitle = ({children}) => {
  return (
      <div className={`text-red-500 text-3xl font-medium py-4`}>
          <Head>
              <title>{children}</title>
          </Head>
          {children}
      </div>
  )
}
