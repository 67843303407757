import {Logo} from "./logo";
import {NavMenu, NavMenuMobile} from "./navmenu";
import {useTranslation} from "next-i18next";
import {Wallet} from "./wallet";
import Link from 'next/link'
import {RiMenuLine} from "react-icons/ri";
import {useCallback, useEffect, useState} from "react";
import {useRouter} from "next/router";


export const NavBar = () => {
    const router = useRouter()
    const { t } = useTranslation();
    const [mobileMenuIsHidden, setMobileMenuHidden] = useState(true);

    const mobileMenuToggle = useCallback((e)=>{
        e.preventDefault();
        setMobileMenuHidden(!mobileMenuIsHidden)
    }, [mobileMenuIsHidden]);

    useEffect(()=>{
        !mobileMenuIsHidden && setMobileMenuHidden(true);
    }, [router.asPath])

  return (
      <section className="w-full px-4 lg:px-12 pb-4 md:pb-0 text-white border-gray-100 bg-black shadow-sm">
          <div className=" flex flex-col items-start md:items-center justify-between xl:pl-16 xl:pr-20 mx-auto md:flex-row  ">
              <div className={`flex flex-row items-center`}>
                  <a href={`#`} onClick={mobileMenuToggle} className="text-4xl p-2 -ml-2 mr-1 xl:hidden ">
                    <RiMenuLine />
                  </a>
                  <Link href={`/`}>
                    <div className={`flex flex-1 cursor-pointer items-center mb-0 font-medium py-4 text-white title-font text-2xl lg:text-4xl`}>
                      <Logo />
                    </div>
                  </Link>
              </div>

              {!mobileMenuIsHidden ? <NavMenuMobile /> : null}
              <NavMenu className={`hidden xl:block`} />
              <div className="items-center flex w-full md:w-auto lg:h-full block md:block">
                  <Wallet className={`w-full md:w-auto`}/>
              </div>
          </div>
      </section>
  )
}
