import {CHAIN_ID} from "../wallet/wallet.connectors";
import {CONTRACT_ABI_TYPE} from "../wallet/contracts/abi.config";

export const COMICS_SERIES_IDS = {
    NAKOMOTO_ADVENTURES: 'nakomotoadventures',
    CRYPTO_HEROES: 'cryptoheroes',
}

export const COMICS_SERIES = [
    {
        id: COMICS_SERIES_IDS.NAKOMOTO_ADVENTURES,
        issues: [
            {
                id: 1, isIssued: true, hasCover: true, mintPrice: 0.1,
                contract: {
                    abiType: CONTRACT_ABI_TYPE.COMIC_WITH_REF,
                    chains: {
                        [CHAIN_ID.ETH_TESTNET_RINKEBY]: '0xDe8A77FfB5F41515b08A31fc6c14939c1879b002'
                    },
                },
            },
            {id: 2, isIssued: false, hasCover: false},
            {id: 3, hasCover: false},
            {id: 4, hasCover: false},
            {id: 5, hasCover: false},
        ],
    },
    {
        id: COMICS_SERIES_IDS.CRYPTO_HEROES,
        issues: [
            {id: 1, hasCover: false},
            {id: 2, hasCover: false},
            {id: 3, hasCover: false},
            {id: 4, hasCover: false},
            {id: 5, hasCover: false},
        ],
    }
]

export function getComicSeries(comicId){
    for(let i=0;i<COMICS_SERIES.length;i++){
        if(COMICS_SERIES[i].id === comicId) return COMICS_SERIES[i];
    }

    return false;
}

export function getComicIssue(comicId, issueId){
    let comicSeria = typeof comicId === 'object' ? comicId : getComicSeries(comicId);
    if(!comicSeria) return false;

    issueId = parseInt(issueId);
    for(let k=0;k<comicSeria.issues.length;k++){
        if(comicSeria.issues[k].id === issueId){
            return comicSeria.issues[k];
        }
    }

    return false;
}