import {CHAIN_DATA} from "../wallet.connectors";
import {Contract} from "@ethersproject/contracts";

const EXPLORER_VIEW_TYPE = {
    ADDRESS: "address",
    TOKEN: "token",
    TRANSACTION: "tx",
}

function getExplorerUrlByChainId(chainId, address, viewType){
    if(!chainId || !CHAIN_DATA[chainId]) return '';
    return `${CHAIN_DATA[chainId].block_explorer}/${viewType}/${address}`;
}

export function getExplorerAddressUrlByChainId(chainId, address){
    return getExplorerUrlByChainId(chainId, address, EXPLORER_VIEW_TYPE.ADDRESS);
}


export function getExplorerTokenUrlByChainId(chainId, address){
    return getExplorerUrlByChainId(chainId, address, EXPLORER_VIEW_TYPE.TOKEN);
}

export function getExplorerTransactionUrlByChainId(chainId, address){
    return getExplorerUrlByChainId(chainId, address, EXPLORER_VIEW_TYPE.TRANSACTION);
}

export function createContract(contractId, abi, signer){
    return new Contract(contractId, abi, signer);
}
