
import Badge, {BadgePurple, BadgeRed} from "../badge";
import {Trans} from "next-i18next";

export const HeaderPromo = () => {
  return (
        <header className="relative w-full text-gray-700 body-font px-4 lg:px-12">
            <div className=" flex flex-col items-start justify-between mx-auto md:flex-row">
                <img src="/img/header/top-banner.png"
                     srcSet="/img/header/top-banner@2x.png 2x,/img/header/top-banner@3x.png 3x"
                     className="h-16 md:h-20 lg:h-28 -ml-14 md:-ml-12 lg:ml-0" />
                <span className="absolute top-3 lg:top-7 left-6 md:left-40 lg:left-96 md:w-3/5 xl:w-2/6 text-white text-sm md:text-lg">
                    <Trans
                        i18nKey="header_promo"
                        values={{}}
                        components={{ BadgeRed: <BadgeRed />, BadgePurple: <BadgePurple />, br: <br/> }}
                    />
                </span>

                <img src="/img/header/d-2.png"
                     srcSet="/img/header/d-2@2x.png 2x,/img/header/d-2@3x.png 3x"
                     className="h-28 hidden xl:block" />
            </div>
        </header>
  )
}
