
import styles from './cover.module.css'
import {useMemo} from "react";
import {COMICS_SERIES_IDS} from "../../config/meta";
import {useTranslation} from "next-i18next";
import {useRouter} from "next/router";
import {useOurComicContract} from "../../wallet/hooks/comic.contract";

const BORDER_TYPE = {
    WHITE: 'white',
    GRADIENT: 'gradient',
}

export default function Cover({
                                coverUrl = false,
                                  seriaId, issueId, hasCover, borderType = BORDER_TYPE.WHITE,
                              blured,
                              className = '',
                              classNameImg = '',
                              css = {w: 64},
                              children }) {
    const router = useRouter()
    //w-fit
    return (
        <div className={`relative pr-2 pt-2 pb-2 min-w-0 ${className}`}>
            <div className={`z-10 absolute ${borderType === BORDER_TYPE.WHITE ? 'border-white' : styles.borderGradient} border left-2 right-0 bottom-0 top-0`}>&nbsp;</div>
            <div className={`z-20 relative bg-blur-color ${blured ? 'blur-sm' : ''} `}>
                {hasCover ?
                    <img src={coverUrl || `/img/comics/${seriaId}/issue${issueId}/cover_${router.locale}.jpg`}
                         className={`z-20 w-${css.w || 64} relative ${classNameImg}`} />
                    : <img src={`/img/comics/cover_empty_${router.locale}.png`}
                           className={`z-20 w-${css.w || 64} relative ${classNameImg}`} />
                }
                {children}

                {blured ? <div className={`z-40 absolute bottom-0 top-0 right-0 left-0 bg-blur-color `}>&nbsp;</div> : null}
            </div>
        </div>
    )
}

export function CoverInfo( props ) {
    const { t } = useTranslation();
    const gradient = useMemo(()=>{
        switch (props.issueId){
            case 2: return 'from-purple-600/70 to-red-700/70';
            case 3: return 'from-yellow-400/70 to-yellow-500/70';
            case 4: return 'from-green-700/70 to-green-500/70';
            case 5: return 'from-amber-400/70 to-amber-300/70';
            case 1:
            default:
                return 'from-purple-900/70 to-cyan-700/70';
        }
    }, [props.issueId])
    return (
        <Cover borderType={BORDER_TYPE.GRADIENT} {...props}>
            <div className={`hidden md:block  bg-black bottom-0 z-30 text-white relative text-sm md:text-base py-1 px-3 bg-black `}>
                <span className={`z-40`}>{t(`issue_num`, {issueId: props.issueId})}</span><br/>
                <div className={`text-teal-300 z-40 truncate `}>
                    {t(`seria.${props.seriaId}.issue_${props.issueId}.title`)}
                </div>

                <div className={`z-30 absolute -bottom-2 right-0 overflow-hidden`}>
                    <div className={` font-['Impact'] font-bold -mr-1.5 md:-mr-3.5 lg:-mr-4.5 xl:-mr-6 text-4xl md:text-8xl xl:text-9xl text-transparent bg-clip-text bg-gradient-to-br ${gradient}`}>
                        0{props.issueId}
                    </div>
                </div>
            </div>
        </Cover>
    )
}

export function MyCoverInfo( props ) {
    const { t } = useTranslation();
    const { myBalance } = useOurComicContract(props.contract)
    // let myBalance = 0;
    // if(props.contract) {
    //     const { myBalance: _myBalance } = useOurComicContract(props.contract)
    //     myBalance = _myBalance;
    // }

    return (
        <CoverInfo {...props} blured={!myBalance || myBalance <= 0} />
    )
}
