import Script from "next/script";
import {HeaderPromo, HeaderWarship} from "../header";
import {NavBar} from "../navbar";
import Footer from "../footer";
import {Subscribe} from "../block";
import {SubscribeSocial} from "../block/subscribe";

export const LAYOUT_TYPE = {
    EMPTY: 'empty',
    MAIN: 'main',
}

export default function Layout({ children }) {
    return (
        <>
            {/*<HeaderWarship />*/}
            <HeaderPromo />
            <NavBar />
            <main>{children}</main>
            {/*<Subscribe />*/}
            <SubscribeSocial />
            <Footer />
        </>
    )
}