import Button, {BUTTON_TYPE} from "./index";
import {useCallback} from "react";
import NiceModal from "@ebay/nice-modal-react";
import {ModalWalletProvider} from "../modal";
import {useTranslation} from "next-i18next";
import {useNavMenuTranslation} from "../comicsreader/hook";

export function ButtonWalletConnect({type = BUTTON_TYPE.BIG, ...rest}) {
    const { t } = useNavMenuTranslation();
    const selectProvider = useCallback(async () => {
        NiceModal.show(ModalWalletProvider, {})
    }, []);

    return (
        <Button onClick={selectProvider} type={type} {...rest}>{t(`navmenu.connect_wallet`)}</Button>
    )
}
