import Flags from "country-flag-icons/react/3x2";
import {useTranslation} from "next-i18next";


export const SupportUkraine = () => {
    const { t } = useTranslation();
  return (
      <div className={`mb-4 text-[0.7rem] flex flex-row items-start space-x-2`}>
          <Flags.UA title="Ukraine" className={`w-8 flex-shrink-0 mt-1`}/>
          <span className={`flex-initial`}>{t(`support_ukraine`)}</span>
      </div>
  )
}
