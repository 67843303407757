import {useCallback, useEffect, useMemo, useState} from "react";
import {useWeb3React} from "@web3-react/core";
import { Contract } from '@ethersproject/contracts';
import {ABI, CONTRACT_ABI_TYPE} from "../contracts/abi.config";
import {loadContractMetadata, mergeObjects} from "../helpers/contract.metadata";
import {LOCALE} from "../../config";
import {utils} from "ethers";



export function useIComicContract(contractAddress) {
    const {active, library, account, chainId} = useWeb3React();
    const [contract, setContract] = useState(false);

    useEffect(()=>{
        if(!active) return setContract(false);
        let _contract = new Contract(contractAddress, ABI[CONTRACT_ABI_TYPE.COMIC], library.getSigner(account).connectUnchecked() );
        setContract(_contract);
    }, [contractAddress, active, account])

    return contract;
}

export function useIComicTokenInfo({contractAddress, tokenId, locale = LOCALE.EN}) {
    const contract = useIComicContract(contractAddress);
    const [comicInfo, setComicInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(()=>{
        if(!contract) return false;
        async function getComicInfo(){
            setIsLoading(true);
            setError(false);
            try{
                let info = {};

                let infoResponse = await Promise.allSettled([
                    contract.name(),
                    contract.ownerOf(tokenId).then(async (addr)=>await contract.signer.getAddress() === addr, (e)=>false),
                    contract.tokenURI(tokenId)
                        .then(async (url)=>loadContractMetadata(url, locale))
                        .catch((e)=>false),
                    contract.tokenURIPrivate(tokenId)
                        .then(async (url)=>loadContractMetadata(url, locale))
                        .catch((e)=>false),
                ])
                info.name = infoResponse[0].value;
                info.isOwner = infoResponse[1].value;
                info = mergeObjects(info, infoResponse[2].value);
                info = mergeObjects(info, infoResponse[3].value);
                info.canReadComic = !!infoResponse[3].value;
                // info.meta = infoResponse[2].value;
                // info.metaPrivate = infoResponse[3].value;

                setComicInfo(info);
            }catch(e){
                console.log(e);
                setError('Error on get contract info metadata.' && e.message);
            }
            setIsLoading(false);
        }
        getComicInfo();
    }, [contract, tokenId, locale])

    return {
        comicInfo,
        isLoading,
        error,
        contract,
    };
}

export function useOurComicContract(props = {}) {
    const [contracts, setContracts] = useState({});
    const {active, library, account, chainId} = useWeb3React();
    const {abiType, chains} = props;

    let contractId = false;
    if(abiType && chains && chains[chainId]) contractId = chains[chainId];

    useEffect(()=>{
        setContracts({});
    }, [account])

    const onTransfer = useCallback((from, to, tokenId)=>{
        setContracts((prev)=>{
            if(!prev[chainId] || !prev[chainId][contractId]) return prev;
            if(from === account) prev[chainId][contractId].myBalance--;
            else if(to === account) prev[chainId][contractId].myBalance++;

            return {...prev};
        })
    }, [contractId, chainId, account])

    useEffect(async ()=>{
        if(!contractId){ return false; }

        if(!contracts[chainId] || !contracts[chainId][contractId]){
            let _contract = new Contract(contractId, ABI[abiType], library.getSigner(account).connectUnchecked() );
            let filterToMe = _contract.filters.Transfer(null, account);
            let filterFromMe = _contract.filters.Transfer(account);
            _contract.on(filterFromMe, onTransfer);
            _contract.on(filterToMe, onTransfer);

            let myBalance = (await _contract.balanceOf(account)).toNumber();
            let firstTokeId = myBalance > 0 ? (await _contract.tokenOfOwnerByIndex(account, 0)).toNumber() : false;

            contracts[chainId] = {
                ...(contracts[chainId] || {}),
                [contractId]: {
                    contractAddress: contractId,
                    chainId: chainId,
                    contract: _contract,
                    myBalance: myBalance,
                    firstTokenId: firstTokeId,
                },
            }
            setContracts({...contracts});
        }
    }, [abiType, contractId, contracts, active, library, chainId])

    const contractData = useMemo(()=>{
        if(!contracts || !contracts[chainId] || !contracts[chainId][contractId]) return false;
        return contracts[chainId][contractId];
    }, [contracts, chainId, contractId])

    return contractData;
}


export function useMintPrice({contract, defaultMintPrice = false}) {
    const [mintPrice, setMintPrice] = useState();

    useEffect(()=>{
        setMintPrice(defaultMintPrice);
    }, [defaultMintPrice])

    useEffect(async ()=>{
        if(!contract) return;

        const response = await contract.mintPrice();
        setMintPrice(utils.formatEther(response));
    }, [contract])

    return {mintPrice};
}