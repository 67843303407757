import { InjectedConnector } from '@web3-react/injected-connector';
// import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
// import { WalletLinkConnector } from '@web3-react/walletlink-connector';

// const RPC_URLS = {
//     1: 'https://mainnet.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4',
//     4: 'https://rinkeby.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4'
// };

export const CHAIN_ID = {
    ETH_MAINNET: 1,
    ETH_TESTNET_ROPSTEN: 3,
    ETH_TESTNET_RINKEBY: 4,
    ETH_TESTNET_GOERLY: 5,
    ETH_TESTNET_KOVAN: 42,

    BNB_MAINNET: 56,
    BNB_TESTNET: 97,
}

export const CHAIN_DATA = {
    [CHAIN_ID.ETH_MAINNET]: {
        "block_explorer": "https://etherscan.io",
    },
    [CHAIN_ID.ETH_TESTNET_RINKEBY]: {
        "block_explorer": "https://rinkeby.etherscan.io",
    },
}

//metamask
export const injected = new InjectedConnector({
    // supportedChainIds: [
    //     CHAIN_ID.ETH_MAINNET,
    //     CHAIN_ID.ETH_TESTNET_ROPSTEN,
    //     CHAIN_ID.ETH_TESTNET_RINKEBY,
    //     CHAIN_ID.ETH_TESTNET_GOERLY,
    //     CHAIN_ID.ETH_TESTNET_KOVAN,
    //
    //     CHAIN_ID.BNB_MAINNET,
    //     CHAIN_ID.BNB_TESTNET,
    // ]
});

//
// export const walletconnect = new WalletConnectConnector({
//     rpc: {
//         1: RPC_URLS[1],
//         4: RPC_URLS[4]
//     },
//     qrcode: true,
//     pollingInterval: 15000
// });
//
//
// export function resetWalletConnector(connector) {
//     if (connector && connector instanceof WalletConnectConnector) {
//         connector.walletConnectProvider = undefined;
//     }
// }
//
// //coinbase
// export const walletlink = new WalletLinkConnector({
//     url: RPC_URLS[4],
//     appName: 'demo-app',
//     supportedChainIds: [ 1, 4 ]
// });