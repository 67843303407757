import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {useCallback, useEffect, useMemo} from "react";
import {useTranslation} from "next-i18next";
import {DiscordIcon, TelegramIcon, TwitterIcon} from "../social";


export const ModalSubscribe = NiceModal.create(({  }) => {
    const { t } = useTranslation();

    const modal = useModal();
    const hide = useCallback(()=>{
        modal.hide();
        setTimeout(()=>modal.remove(), 300);
    }, [modal])


    return (
        <div id="subscribeModal" tabIndex="-1" aria-hidden="true"
             className={`${!modal.visible ? 'hidden' : ''} bg-gray-700/40 flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full`}>
            <div className="relative p-4 w-full max-w-lg h-full md:h-auto flex items-center justify-center">

                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    <div
                        className="flex justify-between items-center py-4 px-4 rounded-t border-b dark:border-gray-600">
                        <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                            {t('modal.subscribe.title')}
                        </h3>
                        <button type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={hide}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>
                    </div>

                    <div className="p-6 pt-6 text-left text-gray-200 md:px-12">

                        <h3 className="mb-5 text-lg font-normal ">
                            {t(`modal.subscribe.description`)}
                        </h3>
                        <ul className="list-disc ml-4 mb-8">
                            <li>{t(`modal.subscribe.li1`)}</li>
                            <li>{t(`modal.subscribe.li2`)}</li>
                            <li>{t(`modal.subscribe.li3`)}</li>
                            <li>{t(`modal.subscribe.li4`)}</li>
                        </ul>

                        <div className={`flex flex-row space-x-12 my-4 mt-6 text-white justify-center items-center`}>
                            <TwitterIcon />
                            <DiscordIcon />
                            <TelegramIcon />
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
});