import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {useWeb3React} from "@web3-react/core";
import {useCallback, useEffect} from "react";
import {CHAIN_ID, injected} from "../../wallet/wallet.connectors";
import {Trans, useTranslation} from "next-i18next";
import {BadgeRed} from "../badge";


export const ModalWalletChangeNetwork = NiceModal.create(({  }) => {
    const { t } = useTranslation();
    const {chainId} = useWeb3React();
    const modal = useModal();
    const hide = useCallback(()=>{
        modal.hide();
        setTimeout(()=>modal.remove(), 300);
    }, [modal])

    useEffect(()=>{
        if(chainId === CHAIN_ID.ETH_MAINNET || chainId === CHAIN_ID.ETH_TESTNET_RINKEBY){
            hide();
        }
    }, [chainId])

    return (
        <div id="walletModal" tabIndex="-1" aria-hidden="true"
             className={`${!modal.visible ? 'hidden' : ''} flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full`}>
            <div className="relative p-4 w-full max-w-md h-full md:h-auto">

                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div
                        className="flex justify-between items-center py-4 px-6 rounded-t border-b dark:border-gray-600">
                        <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                            {t('modal.walletchangenetwork.title')}
                        </h3>
                        {/*<button type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={hide}
                                >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
    clipRule="evenodd"/>
                            </svg>
                        </button>*/}
                    </div>

                    <div className="p-6">
                        <p className="text-sm font-normal text-gray-500 dark:text-white">
                            <Trans
                                i18nKey="modal.walletchangenetwork.description"
                                values={{network: t(`network.${chainId}`)}}
                                components={{ spanPurple: <span className={`text-teal-300`} />, BadgeRed: <BadgeRed />, br: <br/> }}
                            />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
});