import NiceModal from "@ebay/nice-modal-react";

import {ModalWalletProvider} from './wallet.provider'
import {ModalIssueMinted} from './issue.minted'
import {ModalSubscribe} from "./subscribe";

export {ModalWalletProvider, ModalIssueMinted}

export function showModalIssueMinted(props){
    NiceModal.show(ModalIssueMinted, props)
}

export function showModalSubscribe(props){
    NiceModal.show(ModalSubscribe, props)
}