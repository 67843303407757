import {AiFillTwitterCircle} from "react-icons/ai";
import {FaDiscord, FaTelegramPlane} from "react-icons/fa";
import {SOCIAL} from './../../config'


export function SocialIcon({icon, title, className = '', url}){
    return (
        <a href={url} target={'_blank'} rel="noreferrer">
            <div className={`flex flex-col  justify-center items-center cursor-pointer  ${className}`}>
                <div className={`flex justify-center items-center rounded-full bg-white hover:bg-gray-300 h-20 w-20`}>
                    <icon.type {...icon.props} />
                </div>
                <span className={`mt-2 font-bold `}>{title}</span>
            </div>
        </a>
    )
}

export function TwitterIcon(props){
    return <SocialIcon icon={<AiFillTwitterCircle className={`text-8xl text-twitter`} />} title={`Twitter`} url={SOCIAL.TWITTER.url} {...props} />
}

export function DiscordIcon(props){
    return <SocialIcon icon={<FaDiscord className={`text-7xl text-discord`} />} title={`Discord`} url={SOCIAL.DISCORD.url} {...props} />
}

export function TelegramIcon(props){
    return <SocialIcon icon={<FaTelegramPlane className={`text-6xl text-telegram`} />} title={`Telegram`} url={SOCIAL.TELEGRAM.url} {...props} />
}

