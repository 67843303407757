
import {ButtonWalletConnect} from "../button";
import {useTranslation} from "next-i18next";
import {useWeb3React} from "@web3-react/core";
import NiceModal from '@ebay/nice-modal-react';
import {CHAIN_ID, injected} from "../../wallet/wallet.connectors";
import {useCallback} from "react";
import {ModalWalletProvider} from "../modal";
import {useIsWalletAutoLoginActive} from "../../wallet/hooks/autologin";


export const Wallet = ({className}) => {
    const { t } = useTranslation();
    const web3Context = useWeb3React();
    const {isAutoLoginActive, setIsAutoLoginActive} = useIsWalletAutoLoginActive();

    const walletDeactivate = useCallback(async (e) => {
        try {
            web3Context.deactivate();
            setIsAutoLoginActive(false);
        } catch (ex) { console.log('walletDeactivate', ex); }
        e.preventDefault();
    }, [web3Context.deactivate, setIsAutoLoginActive]);



    return (
        <>
            {web3Context.account ? null : <ButtonWalletConnect className={className} />}

            <button data-dropdown-toggle="dropdownInformation"
                    data-dropdown-placement={"bottom-start"}
                    className={`${web3Context.account ? '' : 'hidden'} 
                    flex justify-end  items-center text-right 
                    px-2 font-medium hover:text-red-500 hover:border-red-500
                    ${className}`}
                    type="button">
                <div>
                    <span className={`text-sm`}>
                        {web3Context.chainId !== CHAIN_ID.ETH_MAINNET ? <span className={`text-red-500 font-bold mr-2`}>{t(`network.${web3Context.chainId}`)}</span> : null}
                        {t(`account.account`)}:</span><br/>
                    {`${web3Context.account?.substring(0,5)}...${web3Context.account?.substring(web3Context.account?.length-5)}`}
                </div>
                <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
                </svg>
            </button>

            <div id="dropdownInformation"
                 className="hidden z-40 w-48 bg-white right-0 rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
                {/*<div className="py-3 px-4 text-sm text-gray-900 dark:text-white">
                    <div>{t(`account.yourwallet`)}</div>
                    <div className="font-medium truncate text-xs">{web3Context.account}</div>
                </div>*/}
                <div className="py-1">
                    <a href="#"
                       onClick={walletDeactivate}
                       className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">
                        {t(`account.signout`)}
                    </a>
                </div>
            </div>
        </>
    )
}
