import Link from 'next/link'
import {BadgeRed, BadgeYellow} from "../badge";
import Cover from "../cover";
import {COMICS_SERIES, COMICS_SERIES_IDS, getComicIssue} from '../../config/meta';
import {Trans, useTranslation} from "next-i18next";
import {useOurComicContract} from "../../wallet/hooks/comic.contract";
import {useCallback, useEffect, useMemo, useState} from "react";
import {getExplorerAddressUrlByChainId, getExplorerTokenUrlByChainId} from "../../wallet/helpers";
import {MintBlock, MintComingSoonBlock} from "./mint";
import {urlIssueDetails} from "../../helpers/urls";


function makeComicsReaderUrl(contractAddress, tokenId){
    return `/comicsreader/${contractAddress}/${tokenId}`
}

export function IssueTitle({seriaId, comicIssue, showContract = false, className = ''}){
    const { t } = useTranslation();
    const { contractAddress, chainId } = useOurComicContract(comicIssue.contract);
    const blockExplorerAddressUrl = useMemo(
        ()=>getExplorerAddressUrlByChainId(chainId, contractAddress), [chainId, contractAddress]
    );

    return (
        <div className={`text-left text-white ${className}`}>
            <div><BadgeYellow>{t(`issue_num`, {issueId: comicIssue.id})}</BadgeYellow></div>
            <div>
                <span className={`text-3xl lg:text-4xl mt-1 border-b border-white uppercase`}>{t(`seria.${seriaId}.issue_${comicIssue.id}.title`)}</span>
                {showContract && contractAddress ? <>
                        <br/>
                        <small className={`text-[0.6rem] lg:text-xs`}>
                            <a href={blockExplorerAddressUrl} target={"_blank"} rel="noreferrer">{t(`contract_address`, {address: contractAddress})}</a>
                        </small>
                    </>
                    : null}
            </div>
        </div>
    )
}


export const BlockNewIssue = ({seriaId = COMICS_SERIES_IDS.NAKOMOTO_ADVENTURES, issueId = 1}) => {
    const { t } = useTranslation();
    const comicIssue = useMemo(()=>{
        return getComicIssue(seriaId, issueId);
    }, [seriaId, issueId])
    const { contractAddress } = useOurComicContract(comicIssue.contract);

  return (
      <div className="w-full text-white body-font bg-cover bg-top md:bg-[url('/img/block/bg-newissue@2x.jpg')] ">
          <div className={`py-8 px-8 lg:px-20 xl:px-40 md:bg-gradient-to-r from-black/80 to-gray-300/5`}>
              <div className=" flex flex-col md:flex-row flex-col-reverse items-start justify-between my-4 mx-auto space-y-reverse space-y-8">

                  <div className="flex flex-1 flex-col items-start justify-between  ">

                      <IssueTitle seriaId={seriaId} comicIssue={comicIssue} showContract={true} />
                      <div className={`mt-6 text-teal-300 text-lg font-bold`}>{t(`seria.with_issue_title`, {issueTitle: t(`seria.nakomotoadventures.title`)})}</div>
                      <div className={`mt-4 mb-8 text-base`}>
                          <Trans
                              i18nKey="seria.nakomotoadventures.issue_1.promo_text"
                              values={{}}
                              components={{ br: <br/> }}
                          />
                      </div>

                      {contractAddress ? <MintBlock comicIssue={comicIssue} /> : <MintComingSoonBlock comicIssue={comicIssue} />}
                  </div>
                  <div className=" flex flex-1 flex-col items-center ">

                      <Link href={urlIssueDetails(seriaId, issueId)}><a>
                        <Cover seriaId={seriaId} issueId={issueId} hasCover={true} css={{w:' '}} classNameImg={`w-full md:w-64`}  />
                      </a></Link>

                  </div>

              </div>
          </div>
      </div>
  )
}
