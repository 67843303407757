import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {useCallback, useEffect, useMemo} from "react";
import {Trans, useTranslation} from "next-i18next";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {BlackButton, RedButton, TransparentButton} from "../button";
import {IssueTitle} from "../block/newissue";
import {getExplorerTransactionUrlByChainId} from "../../wallet/helpers";


export const ModalIssueMinted = NiceModal.create(({ chainId, txHash, comicIssue, readUrl }) => {
    const { t } = useTranslation();

    const modal = useModal();
    const hide = useCallback(()=>{
        modal.hide();
        setTimeout(()=>modal.remove(), 300);
    }, [modal])
    const blockExplorerTxUrl = useMemo(()=>{
        return getExplorerTransactionUrlByChainId(chainId, txHash)
    }, [chainId, txHash])


    return (
        <div id="walletModal" tabIndex="-1" aria-hidden="true"
             className={`${!modal.visible ? 'hidden' : ''} bg-gray-700/40 flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full`}>
            <div className="relative p-4 w-full max-w-lg h-full md:h-auto flex items-center justify-center">

                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4 pt-8">

                    <div className="p-6 pt-0 text-center">
                        <IssueTitle comicIssue={comicIssue} />

                        <AiOutlineCheckCircle className="mx-auto mt-4 mb-4 w-16 h-16 text-lime-600" />

                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {t(`modal.issueminted.mintedsuccessfully`)}
                        </h3>

                        <div className={`flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2`}>
                            <RedButton onClick={hide} href={readUrl}>{t(`read_issue`)}</RedButton>
                            <TransparentButton href={blockExplorerTxUrl} hrefInNewTab={true}>{t(`modal.issueminted.btn_showtransaction`)}</TransparentButton>
                            <TransparentButton onClick={hide}>{t(`modal.close`)}</TransparentButton>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
});