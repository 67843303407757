import {useState} from "react";
import Link from 'next/link'
import { useRouter } from "next/router";
import {useTranslation} from "next-i18next";
import {useWeb3React} from "@web3-react/core";
import {useMenu} from "../../hooks/usemenu";
import {useNavMenuTranslation} from "../comicsreader/hook";


export const NavMenu = ({className}) => {
    const { t } = useNavMenuTranslation();
    const router = useRouter();
    const [menu, setMenu] = useMenu();
    return (
        <nav className={`flex h-full items-center justify-center pl-6 ml-6  text-base font-extrabold ${className}`}>
            {menu.map((item, idx, allItems)=>{
                return (
                    <Link key={idx} href={item.href}>
                        <a className={`mr-6 2xl:mr-12 pt-6 pb-5 px-2 font-medium border-b-2 border-solid ${router.pathname === item.href || item.isActive ? 'text-red-500 border-red-500' : 'border-transparent hover:text-red-500 hover:border-red-500'}`}>
                            {t(`navmenu.${item.title}`)}
                        </a>
                    </Link>
                )
            })}
        </nav>
    )
}

export const NavMenuMobile = ({className}) => {
    const { t } = useTranslation('navmenu');
    const router = useRouter();
    const [menu, setMenu] = useMenu();
    return (
        <nav className={`flex flex-col items-start justify-center p-4 text-base font-extrabold ${className}`}>
            {menu.map((item, idx, allItems)=>{
                return (
                    <Link key={idx} href={item.href}>
                        <a className={`mr-2 py-3 font-medium  ${router.pathname === item.href || item.isActive ? 'text-red-500 ' : ' hover:text-red-500 '}`}>
                            {t(`navmenu.${item.title}`)}
                        </a>
                    </Link>
                )
            })}
        </nav>
    )
}

export const NavMenuFooter = () => {
    const { t } = useTranslation('navmenu');
    const {account} = useWeb3React();
    const [menu, setMenu] = useMenu();
    return (
        <nav className="flex flex-col text-sm">
            {menu.map((item, idx, allItems)=>{
                return (
                    <Link key={idx} href={item.href}>
                        <a className={` `}>{t(`navmenu.${item.title}`)}</a>
                    </Link>
                )
            })}
            {!account ? <a key={'connectwallet'} href="#_" className={`underline text-teal-300 `}>{t(`navmenu.connect_wallet`)}</a> : null}
        </nav>
    )
}
