
import {HeaderTextLine} from "./headertext";
import {CoverInfo, MyCoverInfo} from "../cover";
import {useTranslation} from "next-i18next";
import Link from 'next/link'
import {urlIssueDetails} from "../../helpers/urls";

export const SeriaPreview = ({seria, className}) => {
    const { t } = useTranslation();
  return (
      <div className={`w-full text-gray-700 ${className}`}>

          <HeaderTextLine>{t(`seria.${seria.id}.title`)}</HeaderTextLine>

          <div className=" flex flex-row overflow-x-auto items-start justify-between mx-auto py-4 md:py-8">
              {seria.issues?.map((issue, idx)=>{
                    return (
                        <Link key={idx} href={urlIssueDetails(seria.id, issue.id)}>
                            <a>
                                <CoverInfo seriaId={seria.id} issueId={issue.id} hasCover={issue.hasCover} css={{w:'full'}} className={`flex-shrink-0 lg:flex-shrink w-36 md:w-48 lg:w-auto mr-2 md:mr-4 last:mr-0`} />
                            </a>
                        </Link>
                    )
                })}
          </div>

      </div>
  )
}

export const MySeriaPreview = ({seria, className}) => {
    const { t } = useTranslation();

    return (
        <div className={`w-full text-gray-700 ${className}`}>

            <HeaderTextLine>{t(`seria.${seria.id}.title`)}</HeaderTextLine>

            <div className=" flex flex-row overflow-x-auto items-start justify-between mx-auto py-4 md:py-8">
                {seria.issues?.map((issue, idx)=>{
                    return (
                        <Link key={idx} href={urlIssueDetails(seria.id, issue.id)}>
                            <a>
                                <MyCoverInfo seriaId={seria.id} issueId={issue.id} hasCover={issue.hasCover} contract={issue.contract} css={{w:'full'}} className={`flex-shrink-0 lg:flex-shrink w-36 md:w-48 lg:w-auto mr-2 md:mr-4 last:mr-0`} />
                            </a>
                        </Link>
                    )
                })}
            </div>

        </div>
    )
}
