
import styles from './blocks.module.css'
import {BadgePurple, BadgeRed} from "../badge";
import Button from "../button";
import {Trans, useTranslation} from "next-i18next";
import {DiscordIcon, TelegramIcon, TwitterIcon} from "../social";

export const Subscribe = ({}) => {
    const { t } = useTranslation();
  return (
      <div className={`w-full text-white body-font bg-cover bg-right-top md:bg-left-top bg-[url('/img/block/sub@2x.jpg')] `}>

              <div className=" flex flex-col items-start justify-between mx-auto md:flex-row">

                  <div className="hidden xl:block flex-none flex-col items-start justify-between  py-16 w-2/6 ">

                  </div>
                  <div className={` flex flex-1 flex-col p-4 py-8 md:py-12 lg:p-12 xl:pl-72 items-center  ${styles.subscribeBlur} ${styles.subscribeClipPath}`}>

                      <div className={`text-lg md:text-2xl font-medium text-center`}>
                          <Trans
                              i18nKey="subscribe.title"
                              values={{}}
                              components={{ BadgePurple: <BadgePurple /> }}
                          />
                      </div>

                      <p className={`text-base text-center px-4 md:px-20 py-4`}>
                          {t(`subscribe.text`)}
                      </p>
                      <input className={`w-80 border-0`} type={`text`} defaultValue={``} placeholder={t(`subscribe.input_placeholder`)} />
                      <Button className={`mt-4`} >{t(`subscribe.button_subscribe`)}</Button>
                  </div>

              </div>

      </div>
  )
}

export const SubscribeSocial = ({}) => {
    const { t } = useTranslation();
    return (
        <div className={`w-full text-white body-font bg-cover bg-right-top md:bg-left-top bg-[url('/img/block/sub@2x.jpg')] `}>

            <div className=" flex flex-col items-start justify-between mx-auto md:flex-row">

                <div className="hidden xl:block flex-none flex-col items-start justify-between  py-16 w-2/6 ">

                </div>
                <div className={` flex flex-1 flex-col p-4 py-8 md:py-12 lg:p-12 xl:pl-72 items-center  ${styles.subscribeBlur} ${styles.subscribeClipPath}`}>

                    <div className={`text-lg md:text-2xl font-medium text-center`}>
                        <Trans
                            i18nKey="subscribe_social.title"
                            values={{}}
                            components={{ BadgePurple: <BadgePurple />, nowrap: <span className={`whitespace-nowrap`} /> }}
                        />
                    </div>
                    <p className={`text-base text-center px-4 md:px-20 py-4`}>
                        <Trans
                            i18nKey="subscribe_social.text"
                            values={{}}
                            components={{ br: <br /> }}
                        />
                    </p>

                    <div className={`flex flex-row space-x-12 my-4 mt-6`}>
                        <TwitterIcon />
                        <DiscordIcon />
                        <TelegramIcon />
                    </div>

                </div>

            </div>

        </div>
    )
}
