import {LOCALE} from "../../config";


function prepareUrl(url){
    return url;
}

export function mergeObjects(metaData, lMetaData){
    for(let k in lMetaData){
        if(typeof lMetaData[k] === 'object' && !lMetaData[k].hasOwnProperty('length')){
            metaData[k] = mergeObjects(metaData[k] || {}, lMetaData[k]);
        }else{
            metaData[k] = lMetaData[k];
        }
    }

    return metaData;
}

export function updateLocalizationMeta(metaData, locale = LOCALE.EN){
    if(!metaData?.localization) return Promise.resolve(metaData);

    if(metaData.localization.locales.indexOf(locale) === -1) locale = metaData.localization.default;

    let url = metaData.localization.uri.replaceAll('{locale}', locale);

    return fetch(prepareUrl(url))
        .then(response => response.json())
        .then(lMetaData => {
            if(!lMetaData) return metaData;

            metaData = mergeObjects(metaData, lMetaData);

            console.log('metaData', metaData)
            return metaData;
        }, ()=>metaData);
}

export function loadContractMetadata(url, locale = LOCALE.EN){
    return fetch(prepareUrl(url))
        .then(response => response.json())
        .then(metaData => {
            console.log('metaData', metaData)
            return updateLocalizationMeta(metaData, locale);
        });
}