import {AiFillTwitterCircle} from "react-icons/ai";
import {FaDiscord, FaTelegramPlane} from "react-icons/fa";
import {SOCIAL} from './../../config'


export function FooterIcon({icon, title, className = '', url}){
    return (
        <a href={url} target={'_blank'} rel="noreferrer">
            <div className={`flex flex-col  justify-center items-center cursor-pointer  ${className}`}>
                <div className={`flex justify-center items-center rounded-full hover:bg-gray-300 p-2 text-lg`}>
                    <icon.type {...icon.props} />
                </div>
            </div>
        </a>
    )
}

export function FooterTwitterIcon(props){
    return <FooterIcon icon={<AiFillTwitterCircle  />} url={SOCIAL.TWITTER.url} {...props} />
}

export function FooterDiscordIcon(props){
    return <FooterIcon icon={<FaDiscord  />} url={SOCIAL.DISCORD.url} {...props} />
}

export function FooterTelegramIcon(props){
    return <FooterIcon icon={<FaTelegramPlane  />} url={SOCIAL.TELEGRAM.url} {...props} />
}

