import {useMemo} from "react";

export const HeaderTextLine = ({children}) => {
    let title = useMemo(()=>{
        let [tealText, ...otherText] = children.split(' ');
        return {tealText, otherText}
    }, [children]) ;

  return (

          <div className={`flex uppercase text-lg md:text-2xl lg:md:text-4xl text-white font-bold items-center justify-center`}>
              <div className={`flex-none`}>
                  <span className={`text-teal-300`}>{title.tealText}</span>&nbsp;{title.otherText.join(' ')}
              </div>
              <span className={`flex-1 w-full h-px bg-gradient-to-r from-purple-900 to-cyan-700 ml-6`}>&nbsp;</span>
          </div>

  )
}
