import {BadgeRed} from "../badge";
import {Trans, useTranslation} from "next-i18next";

export const CollectIssues = ({className}) => {
    const {t} = useTranslation();
  return (
      <div className={`w-full text-gray-700  bg-cover bg-center md:bg-[url('/img/block/eks.jpg')] ${className}`}>

              <div className=" flex flex-col items-start justify-between mx-auto md:flex-row bg-[#3b0060] md:bg-transparent">

                  <div className=" flex-1 flex-col items-start justify-between  py-16 h-16 md:h-auto w-full md:w-auto bg-cover bg-center bg-[url('/img/block/eks.jpg')] md:bg-none  ">
                    &nbsp;
                  </div>
                  <div className=" flex flex-1 flex-col items-center p-2 pt-4 md:pt-16 md:p-16 md:pr-16 ">

                        <span className="text-white text-sm md:text-xl font-bold">
                            <Trans
                                i18nKey="collect_issues"
                                values={{ser1: t(`seria.nakomotoadventures.title`), ser2: t(`seria.cryptoheroes.title`)}}
                                components={{ spanPurple: <span className={`text-teal-300`} />, BadgeRed: <BadgeRed />, br: <br/> }}
                            />
                        </span>

                  </div>

              </div>

      </div>
  )
}
