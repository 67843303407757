
import { parseCookies } from 'nookies'

import {Logo} from "../navbar/logo";
import {NavMenuFooter} from "../navbar";
import {Trans, useTranslation} from "next-i18next";
import {FooterDiscordIcon, FooterTelegramIcon, FooterTwitterIcon} from "../social/footericons";


function YourReferrer({}){
    const cookies = parseCookies()

    if(!cookies || !cookies.ref) return null;

    return (
        <span className={`text-center lg:text-left`}>Your referrer: {cookies.ref}</span>
    )
}

export default function Footer({}) {
    const { t } = useTranslation();
    return (
        <footer className="w-full px-4 lg:px-28 py-0 pt-4 text-white bg-black body-font">
            <div className={`flex flex-col lg:flex-row items-start lg:items-center justify-between lg:px-8`}>
                <a className="flex-none  w-full lg:w-auto text-center items-center  font-medium py-2 lg:py-8 text-white title-font text-2xl lg:text-4xl">
                    <Logo />
                </a>
                <div className="flex-none items-start lg:items-center lg:px-12 py-4 lg:py-8 ">
                    <NavMenuFooter />
                </div>
                <div className="flex-1 flex flex-col text-sm">
                    <Trans
                        i18nKey="footer.text"
                        values={{}}
                        components={{ br: <br /> }}
                    />

                    <div className={`flex flex-row mt-2`}>
                        <FooterTwitterIcon />
                        <FooterDiscordIcon />
                        <FooterTelegramIcon />
                    </div>
                </div>
            </div>
            <div className={`flex flex-col lg:flex-row justify-between text-xs border-t border-gray-800 mt-4 py-2 lg:px-8`}>
                <span className={`text-center lg:text-left`}>{t(`footer.copyright`)}</span>

                <YourReferrer />
            </div>

        </footer>
    )
}
