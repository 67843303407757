
import AbiComicRef from './comic.ref.abi.json'
import AbiIComic from './icomic.abi.json'

export const CONTRACT_ABI_TYPE = {
    COMIC: 'comic',
    COMIC_WITH_REF: 'comic_ref',
}

export const ABI = {
    [CONTRACT_ABI_TYPE.COMIC_WITH_REF]: AbiComicRef,
    [CONTRACT_ABI_TYPE.COMIC]: AbiIComic,
}