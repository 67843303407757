import {BadgeYellow} from "../badge";
import {BlackButton, BUTTON_TYPE, RedButton} from "../button";
import Cover from "../cover";
import {COMICS_SERIES, COMICS_SERIES_IDS, getComicIssue} from '../../config/meta';
import {useTranslation} from "next-i18next";
import {useMintPrice, useOurComicContract} from "../../wallet/hooks/comic.contract";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useWeb3React} from "@web3-react/core";
import NiceModal from "@ebay/nice-modal-react";
import {ModalWalletProvider, showModalIssueMinted, showModalSubscribe} from "../modal";
import { utils } from 'ethers'
import {getExplorerAddressUrlByChainId, getExplorerTokenUrlByChainId} from "../../wallet/helpers";
import {SupportUkraine} from "./support.ukraine";


function makeComicsReaderUrl(contractAddress, tokenId){
    return `/comicsreader/${contractAddress}/${tokenId}`
}

export function MintBlock({comicIssue}){
    const { t } = useTranslation();
    const { account } = useWeb3React();
    const { chainId, contractAddress, firstTokenId, contract, myBalance } = useOurComicContract(comicIssue.contract);
    const { mintPrice } = useMintPrice({contract, defaultMintPrice: comicIssue.mintPrice})
    const [mintInProgress, setMintInProgress] = useState(false);
    const [lastMintTx, setLastMintTx] = useState(false);

    const blockExplorerTxUrl = useMemo(
        ()=>getExplorerAddressUrlByChainId(chainId, lastMintTx.transactionHash), [chainId, lastMintTx]
    );

    const selectProvider = useCallback(async () => {
        NiceModal.show(ModalWalletProvider, {})
    }, []);

    const mint = useCallback(async (e)=>{
        e.preventDefault();

        if(!account){
            selectProvider();
            return;
        }
        console.log('contract', contract);
        if(!contract){
            console.error('Contract is empty');
            return;
        }

        setMintInProgress(true);
        const options = {
            gasLimit: 230000,
            value: utils.parseEther(mintPrice),
        };

        try{
            let response = await contract.mint(options);
            response = await response.wait();
            setLastMintTx(response);

            showModalIssueMinted({chainId, txHash: response.transactionHash,
                comicIssue, readUrl: makeComicsReaderUrl(contract.address, firstTokenId)});
        }catch(e){
            console.error(e);
        }
        setMintInProgress(false);
    }, [contract, account, mintPrice, setLastMintTx, firstTokenId])


    return (
        <>
            <SupportUkraine />
            <div className={`flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 w-full`}>
                <RedButton isLoading={mintInProgress} loadingMessage={t(`mint.pleasewait`)}
                        className={`w-full md:w-auto`} onClick={mint}
                        type={BUTTON_TYPE.BIG}>
                    {t(`get_issue`)} <span className={`whitespace-nowrap`}>({mintPrice}ETH + GAS)</span><br/>
                </RedButton>
                {myBalance > 0 ?
                    <BlackButton className={`w-full md:w-auto`} href={makeComicsReaderUrl(contractAddress, firstTokenId)}
                            type={BUTTON_TYPE.BIG}>
                        {t(`read_issue`)}<br/>
                        <span className={`text-[0.5rem] mt-2`}>{t(`balance_issue`, {balance: myBalance})}</span>
                    </BlackButton>
                    : null
                }
            </div>
            {/*<span className={`text-xs mt-4`}>{t(`balance_issue`, {balance: myBalance})}</span>*/}
            {/*{lastMintTx && <span className={`text-[0.6rem] mt-4`}>*/}
            {/*    <a href={blockExplorerTxUrl} target={"_blank"} rel="noreferrer">*/}
            {/*        {t(`minting_tx`, {txHash: lastMintTx.transactionHash})}*/}
            {/*    </a>*/}
            {/*</span>}*/}
        </>
    )
}

export function MintComingSoonBlock({comicIssue}){
    const { t } = useTranslation();
    const { contract } = useOurComicContract(comicIssue.contract);
    const { mintPrice } = useMintPrice({contract, defaultMintPrice: comicIssue.mintPrice})

    const onClick = useCallback(()=>{
        showModalSubscribe();
    }, [])

    return (
        <div className={`flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 w-full`}>
            <RedButton className={`w-full md:w-auto`} onClick={onClick}
                       type={BUTTON_TYPE.BIG}>
                {t(`want_issue`)}<br/>
                {/*<span className={`text-[0.5rem] mt-2`}>{t(`balance_issue`, {balance: myBalance})}</span>*/}
                <span className={`text-[0.5rem] mt-2`}>NFT Comic, Minting: Soon, Minting price: {mintPrice}ETH</span>
            </RedButton>
        </div>
    )
}
