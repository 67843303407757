
export const GA_TRACKING_ID = 'G-RD66HK0QBW';

export const LOCALE = {
    EN: 'en',
    RU: 'ru',
}

export const SOCIAL = {
    TWITTER: {
        url: 'https://twitter.com/CryptoComicsW'
    },
    TELEGRAM: {
        url: 'https://t.me/+EvIpqzOOXyVmYjRi'
    },
    DISCORD: {
        url: 'https://discord.gg/pxSxSVp8Z9'
    }
}