
import {useState} from "react";

const MENU = [
    {title: 'main', isActive: false, href: '/'},
    // {title: 'catalog', isActive: false, href: '/catalog'},
    {title: 'my_comics', isActive: false, href: '/mycomics'},
    // {title: 'roadmap', isActive: false, href: '/roadmap'},
    // {title: 'about', isActive: false, href: '/about'},
    {title: 'about', isActive: false, href: 'https://cryptocomics.world'},
];

export const useMenu = () => {
    const [menu, setMenu] = useState(MENU)

    return [menu, setMenu]
}
