
import { appWithTranslation } from 'next-i18next';
import {useWeb3React, Web3ReactProvider} from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import NiceModal from '@ebay/nice-modal-react';
import Script from 'next/script';

import '../styles/globals.css'
import Layout, {LAYOUT_TYPE} from "../components/layout";
import {useCallback, useEffect} from "react";
import {CHAIN_ID, injected} from "../wallet/wallet.connectors";
import {useIsWalletAutoLoginActive} from "../wallet/hooks/autologin";
import {ModalWalletChangeNetwork} from "../components/modal/wallet.changenetwork";
import {useRouter} from "next/router";
import {GA_TRACKING_ID} from "../config";

function Web3CheckConnection({  }) {
    const web3Context = useWeb3React();
    const {isAutoLoginActive, setIsAutoLoginActive} = useIsWalletAutoLoginActive();

    useEffect(async ()=>{
        if(!isAutoLoginActive) return false;
        try{
            let isAuthorized = await injected.isAuthorized()
            if (isAuthorized && !web3Context.active && !web3Context.error) {
                web3Context.activate(injected)
            }
        }catch(e){ console.error(e); }
    }, [isAutoLoginActive, web3Context.activate, web3Context.error])

    useEffect(async ()=>{
        if(isAutoLoginActive) return false;
        web3Context.active && setIsAutoLoginActive(web3Context.active);
    }, [web3Context.active])

    useEffect(async ()=>{
        if(!web3Context.active) return;
        if(web3Context.chainId === CHAIN_ID.ETH_MAINNET || web3Context.chainId === CHAIN_ID.ETH_TESTNET_RINKEBY) return;

        NiceModal.show(ModalWalletChangeNetwork, {})
    }, [web3Context.active, web3Context.chainId]);

    return null;
}

function CryptoComicsApp({ Component, pageProps }) {
    const router = useRouter();

    const getLibrary = useCallback((provider) => {
        const library = new Web3Provider(provider, 'any');
        library.pollingInterval = 15000;
        return library;
    }, []);

    const AppLayout = Component.layout && Component.layout === LAYOUT_TYPE.EMPTY ? (({children}) => <>{children}</>) : Layout;

    useEffect(() => {
        const handleRouteChange = url => {
            window.gtag('config', GA_TRACKING_ID, { page_path: url })
            window.gtag('event', 'page_view', {
                page_location: `${document.location.origin}${url}`,
                page_title: window.document.title,
            })
        }
        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events])

  return (
      <Web3ReactProvider getLibrary={getLibrary}>
          <Web3CheckConnection />
          <NiceModal.Provider>
              <Script src="https://unpkg.com/flowbite@1.3.4/dist/flowbite.js" strategy={"beforeInteractive"} ></Script>
              <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
              <Script strategy="afterInteractive"
                      id="ga-site"
                      dangerouslySetInnerHTML={{
                          __html: `
                              window.dataLayer = window.dataLayer || [];
                              function gtag(){dataLayer.push(arguments);}
                              gtag('js', new Date());
                            
                              gtag('config', '${GA_TRACKING_ID}', {
                                    page_path: window.location.pathname,
                              });
                              gtag('event', 'page_view', {
                                page_location: window.location.href,
                                page_title: window.document.title,
                              })
                            `,
                  }}
              />
              <Script src="https://cdn.onesignal.com/sdks/OneSignalSDK.js" ></Script>
              <Script
                  id="onesignal-push"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                      __html: `
                          window.OneSignal = window.OneSignal || [];
                          OneSignal.push(function() {
                              OneSignal.init({
                                  appId: "3645ba50-8f33-4b6a-9476-83b98a22fe3b",
                              });
                          });
                    `,
                  }}
              />
              <AppLayout>
                <Component {...pageProps} />
              </AppLayout>
          </NiceModal.Provider>
      </Web3ReactProvider>
  )
}

export default appWithTranslation(CryptoComicsApp);
