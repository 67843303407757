import {useTranslation} from "next-i18next";


export function useComicsReaderTranslation(){
    return useTranslation('comicsreader')
}

export function useNavMenuTranslation(){
    return useTranslation('navmenu')
}
