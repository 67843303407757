
import {HeaderTextLine} from "./headertext";
import {useTranslation} from "next-i18next";
import {useState} from "react";
import {FaTelegram} from "react-icons/fa";

function TeamItem({name, position, avatar, social, className = ''}){
    return (
        <div className={`flex flex-1 basis-1/4 flex-row space-x-4 p-8 bg-black items-start justify-start text-white  ${className} `}>
            <img src={avatar} className={`w-28 h-32  border-sky-800 border-1 object-contain object-center`} />
            <div>
                <div className={`w-full font-bold text-xl my-2 items-center justify-between flex`} >
                    <span>{name}</span>
                    <span>{social?.tg ? <a href={`https://t.me/pupsikus`} target={'_blank'} rel="noreferrer"><FaTelegram className={`inline-block`} /></a> : ''}</span>
                </div>
                {position}
                {/*<div className={`mt-2`}>*/}
                {/*    {social?.tg ? <a href={`https://t.me/pupsikus`} target={'_blank'}><FaTelegram className={`inline-block`} /> Telegram</a> : ''}*/}
                {/*</div>*/}
            </div>

        </div>
    )
}

export const Team = ({className}) => {
    const { t } = useTranslation();
    const [teamList, setTeamList] = useState([
        {name: 'Alek', position: 'Founder/Dev',
            social: {tg: 'https://t.me/pupsikus'},
            avatar: '/img/team/alek.jpg'},
        {name: 'Vika', position: 'Designer', avatar: '/img/team/vika.jpg'},
        {name: 'Jastko', position: '2d Artist', avatar: '/img/team/jastko.jpg'},
        {name: 'Di Frenkas', position: 'Marketing', avatar: '/img/team/difrenkas.jpg'},
        {name: 'n1paT', position: '2d Artist', avatar: '/img/team/roman.jpg'},
        // {name: 'Andrew', position: 'Dev', avatar: false},
    ])
  return (
      <div className={`w-full text-gray-700 body-font py-4 ${className}`}>

          <HeaderTextLine>{t(`team.title`)}</HeaderTextLine>

          <div className=" flex flex-col md:items-start md:justify-center md:space-x-4 space-y-4 first:mt-4 mx-auto md:flex-row flex-wrap py-8">
              {teamList.map((teamUser, idx)=>{
                    return <TeamItem key={idx} {...teamUser} className={`min-w-fit ${idx === 0 ? 'md:mt-4 md:ml-4' : ''} `} />;
                })}
          </div>

      </div>
  )
}
